@import "../0-abstracts/";

.location {
  @include display-flex;
  background-color: $location-bg;
  $self: &;
  &__wrapper {
    @include display-flex;
    #{$self}__map-box {
      @include display-flex;
      #{$self}__map {
        border: none;
        width: 100%;
        aspect-ratio: 2;
        filter: drop-shadow(0.1px 0.1px 3px $gray);
        @include tablet {
          aspect-ratio: 3;
        }
      }
    }
    #{$self}__text-box {
      @include display-flex($align: flex-start);
      #{$self}__text {
        @include font($family: "poppins", $transform: none, $weight: 500);
        color: $text-important;
        text-align: center;
        @include tablet {
          text-align: left;
        }
        &::first-letter {
          text-transform: capitalize;
        }
        span {
          font-weight: 700;
          font-style: italic;
        }
      }
    }
  }
}
