@import "../1-base/";
@import "../2-components/arrowButton";
@import "../2-components/icon";
@import "../2-components/footerLink";

.footer {
  $self: &;
  width: 100%;
  background-color: $ft-bg;
  @include display-flex;
  &__wrapper {
    @include desktop{
      max-width: 1440px;
    }
    width: 100%;
    hr {
      width: 100%;
      border: none;
      border-bottom: 0.25px solid $underline-bold;
    }
    #{$self}-socials {
      @include display-flex;
      &__title-box {
        @include display-flex;
        @include min-width(960px) {
          align-items: flex-start;
          position: relative;
          top: 16px;
        }
        #{$self}-socials__title {
          @include font(
            $color: $text-important,
            $family: "red hat display",
            $transform: none,
            $weight: 400
          );
          &::first-letter {
            text-transform: capitalize;
          }
          @include min-width(960px) {
            margin-bottom: 16px;
          }
        }
      }
      &__icons-wrapper {
        @include display-flex($direction: row, $justify: space-evenly);
        margin: 16px 0;
        .MuiGrid-container .MuiGrid-item {
          @include min-width(960px) {
            @include display-flex($direction: row, $justify: flex-start);
          }
        }
      }
    }
    #{$self}-links {
      @include display-flex($align: flex-start);
      @include min-width(960px){
        position: relative;
        top: 16px;  
      }
      &__title-box {
        #{$self}-links__title {
          @include font(
            $color: $text-important,
            $family: "red hat display",
            $transform: none,
            $weight: 400
          );
          margin-bottom: 16px;
          &::first-letter {
            text-transform: capitalize;
          }
        }
      }
      &__wrapper {
        width: 100%;
        // @include min-width(960px) {
        //   position: relative;
        //   top: 16px;
        // }
      }
    }
  }
}
