@import "../0-abstracts/";

.loading-spinner {
  @include display-flex($width: 100vw, $height: 100vh);
  background-color: $spinner-bg;
  opacity: 0;
  animation: opacity 2.5s ease 1 forwards;
  $self: &;
  &__wrapper {
    @include display-flex($width: 100%);
    position: relative;
    padding: 16px;
    #{$self}__title {
      @include font(
        $family: "sora",
        $color: $black,
        $transform: capitalize,
        $weight: 600
      );
      text-align: center;
      margin-bottom: 32px;
    }
    #{$self}__subtitle {
      @include font($family: "sora", $color: $black, $transform: capitalize);
      text-align: center;
      margin-bottom: 8px;
    }
    img {
      width: 150px;
      @include tablet {
        width: 200px;
      }
      @include desktop {
        width: 300px;
      }
    }
  }
}
