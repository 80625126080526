@import "../0-abstracts/";

.link-icon__box {
  @include display-flex($direction: row, $justify: flex-start);
  @include font(
    $family: "poppins",
    $weight: 400,
    $color: $links,
    $transform: capitalize
  );
  text-decoration: none;
  text-align: left;
  .link {
    color: $links;
    &:hover {
      cursor: pointer;
      color: $links-active;
      transition: 0.5s;
      text-decoration: underline;
    }
  }
  .open-in-new-icon {
    vertical-align: middle;
    margin-right: 4px;
  }
  .phone-icon {
    vertical-align: -18%;
    margin-right: 4px;
  }
}
