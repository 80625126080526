@import "../0-abstracts/";

.advice-row {
  @include display-flex;
  min-height: 400px;
  //   max-height: 700px;
  position: relative;
  //   overflow: hidden;
  $self: &;
  &__wrapper {
    @include display-flex;
    #{$self}__title-box {
      @include display-flex;
      #{$self}__title {
        width: 100%;
        @include font(
          $family: "poppins",
          $weight: 700,
          $color: $black,
          $transform: none
        );
        line-height: 1.5em;
        text-align: center;
        .warn-icon {
          vertical-align: middle;
          margin-right: 8px;
          filter: drop-shadow(-0.1px -0.1px 2px $black);
          font-size: 1.5em;
          path {
            fill: $yellow;
          }
        }
        .map-icon {
          vertical-align: -10%;
          margin-right: 8px;
          font-size: 1.5em;
          filter: drop-shadow(-0.1px -0.1px 2px $black);
          path {
            fill: $red;
          }
        }
        .wallet-icon {
          vertical-align: -25%;
          margin-right: 8px;
          font-size: 1.5em;
          filter: drop-shadow(0.1px 0.1px 2px $gray);
          path {
            fill: brown;
          }
        }
      }
    }
    #{$self}__img-box {
      @include display-flex($height: 100%);
      #{$self}__img {
        @include display-flex($height: 100%);
        box-shadow: 0.1px 0.1px 3px;
        @include min-width(960px) {
          box-shadow: -1px -1px 5px;
        }
      }
    }
    #{$self}__text-container {
      @include min-width(960px) {
        align-content: center;
      }
    }
    #{$self}__text-box {
      @include display-flex;
      #{$self}__text {
        width: 100%;
        @include font(
          $family: "poppins",
          $weight: 500,
          $color: $black,
          $transform: none
        );
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}

.insitu {
  @include min-width(960px) {
    .advice-row__container {
      flex-direction: row-reverse;
    }
  }
}
