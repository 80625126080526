@import "../1-base/";
@import "../2-components/title";
@import "../3-layout/Budget/contactForm";

.budget {
  @include display-flex;
  width: 100%;
  min-height: max-content;
  $self: &;
  // @include min-width(1280px){
  //   border: 2px solid $dark-blue;
  // }
  &__wrapper {
    width: 100%;
    height: 100%;
  }
}
