@import "../0-abstracts/";

.footer-links__link {
  @include display-flex($align: flex-start);
  a {
    @include font(
      $color: $links,
      $family: "red hat display",
      $transform: capitalize,
      $weight: 300
    );
    text-decoration: none;
    display: block;
    &:hover,
    &:active {
      color: $links-active;
    }
    @include min-width(960px) {
      line-height: 36px;
    }
  }
}

.--icon-link a {
  text-transform: none;
}
