/*palette*/
$dark-blue: #24385b;
$blue: #235ab9;
$light-blue: #1089ff;
$yellow:#FDE02C;
$red: red;
$dark-gray: gray;
$gray: #9BA4B4;
// $light-gray: lightgray;
$light-gray: #f5f5f5;
$gray-smoke:#DCE4E7;
$gray-shadow:#a3aba0;
$white-smoke: #efeff1;
$white: #fff;
$black: #000;

/*backgrounds*/
$nav-bg: $black;
$location-bg: $light-gray;
$ft-bg: $light-gray;
$spinner-bg: $yellow;

/*buttons / links*/
$button: $yellow;
$arrow-button: $blue;
$button-active: $light-blue;
$links: $blue;
$links-active: $light-blue;

/*text*/
$text-important: $black;
$placeholder-text: $gray;
$text-normal: $dark-gray;
$underline: $white-smoke;
$underline-semi-bold: $light-gray;
$underline-bold: $dark-gray;

/*others*/
$bullet: $white;
$bullet-active: $light-blue;
$placeholder-input: $dark-gray;
