@import "../../2-components/input";
@import "../../2-components/textarea";
@import "../../2-components/ctaButton";

.contact__form {
  @include display-flex;
  &-inputs {
    width: 100%;
    position: relative;
    left: 8px;
    .MuiGrid-container {
      margin: 24px 0 8px 0;
      position: relative;
      right: 16px;
    }
  }
}
