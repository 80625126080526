@import "../0-abstracts/";

.carousel {
  @include display-flex($height: 100%);
  background-color: $black;
  $self: &;
  &__wrapper {
    @include display-flex($height: 100%);
    overflow-x: hidden;
    @include desktop {
      max-width: 1440px;
    }
    #{$self}__slide-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden !important;
      #{$self}__slide {
        width: 100%;
        height: 100%;
        position: relative;
        &--active-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: left center;
          animation: back-to-screen 4s ease infinite forwards;
          @include min-width(500px) {
            object-fit: contain;
            object-position: center center;
          }
        }
        #{$self}__bullets {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          @include display-flex(
            $width: 85px,
            $direction: row,
            $justify: space-around
          );
          #{$self}__bullet {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            box-shadow: 1px 1px 5px #000;
            background-color: $bullet;
          }
        }
      }
    }
  }
}

.--active-bullet {
  background-color: $bullet-active !important;
}
