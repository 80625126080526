@import "../0-abstracts/";

.nav__bar {
  width: 55%;
  max-width: 550px;
  $self: &;
  &-items {
    @include display-flex(
      $direction: row,
      $height: 100%,
      $justify: space-between
    );
    #{$self}-item-box {
      @include display-flex($height: 100%, $align: flex-end);
      #{$self}-item {
        @include font(
          $family: "red hat display",
          $transform: capitalize,
          $color: $text-important,
          $weight: 400
        );
        min-width: max-content;
        text-decoration: none;
        color: $white;
        filter: drop-shadow(0.5px 0.5px 3px rgba($black, 0.1));
        &:hover,
        &:active {
          color: $yellow;
          transition: 0.3s;
        }
      }
    }
  }
}
