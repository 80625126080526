@import "../0-abstracts/";


.advice-row__grid-container{
  @include min-width(960px){
    margin-top: 64px;
  }
}

.cta-quote__grid-container {
  justify-content: center;
  @include min-width(1280px) {
    margin: 0 0 96px 0;
  }
  .cta-quote__grid-item {
    &:first-of-type {
      @include min-width(1280px) {
        opacity: 0;
        transform: translateX(-150%);
        position: relative;
        top: 75px;
        box-shadow: -30px -15px 0px 0px rgba($dark-blue, 30%);
      }
      .row {
        @include min-width(1280px) {
          border: 2px solid $dark-blue;
        }
      }
    }
    &:nth-of-type(2) {
      @include min-width(1280px) {
        opacity: 0;
        transform: translateX(150%);
        height: 100%;
        position: relative;
        top: 75px;
        box-shadow: 30px 15px 0px rgba($gray-shadow, 30%);
      }
      .row {
        @include min-width(1280px) {
          margin-left: 2px;
        }
      }
    }
  }
}

.contact__grid-container {
  @include min-width(1280px) {
    justify-content: center;
    margin: 0 0 64px 0;
  }
  .contact__grid-item {
    &:first-of-type {
      @include min-width(1280px) {
        opacity: 0;
        transform: translateX(-150%);
        box-shadow: -30px -15px 0px 0px rgba($gray-shadow, 30%);
      }
      .row {
        @include min-width(1280px) {
          height: 100%;
        }
      }
    }
    &:nth-of-type(2) {
      @include min-width(1280px) {
        opacity: 0;
        transform: translateX(150%);
        margin-left: 2px;
        box-shadow: 30px 15px 0px rgba($dark-blue, 30%);
      }
    }
  }
}

.--active-from-left {
  animation: active-from-left 1s ease-in 1 forwards;
}
.--active-from-right {
  animation: active-from-right 1s ease-in 1 forwards;
}