$breakpoints: (
  big-phone: 480px,
  tablet: 768px,
  md: 960px,
  netbook: 1024px,
  notebook: 1240px,
  desktop: 1440px,
);

$company-name: (
  null: 1.1em,
  tablet: 1.1em,
  netbook: 1.3em,
  notebook: 1.3em,
  desktop: 1.4em,
);

$blurred-text: ($company-name);

$row-text: (
  null: 2em,
  tablet: 2.5em,
  netbook: 3em,
  notebook: 3em,
  desktop: 4em,
);

$cta-button: (
  null: 1em,
  tablet: 1em,
  netbook: 1em,
  notebook: 1.1em,
  desktop: 1.2em,
);

$section-title: (
  null: 1.2em,
  tablet: 1.4em,
  netbook: 1.5em,
  notebook: 1.5em,
  desktop: 1.7em,
);

$card-title: (
  null: 1.2em,
  tablet: 1.3em,
  netbook: 1.3em,
  notebook: 1.3em,
  desktop: 1.4em,
);

$item-title: (
  null: 1.1em,
  tablet: 1.1em,
  netbook: 1.3em,
  notebook: 1.3em,
  desktop: 1.4em,
);

$item-text: (
  null: 1em,
  tablet: 1em,
  netbook: 1.2em,
  notebook: 1.2em,
  desktop: 1.3em,
);

$socials-title: ($item-text);

$links-title: ($socials-title);

$link: ($socials-title);

$drawer-item: ($item-title);

$advice-row-title: (
  /*h1 font-size by default*/
  tablet: 2.5em,
  md: 1.7em,
  notebook: 2.5em,
);
