@keyframes active {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes active-from-left {
  0% {
    opacity: 0;
    transform: translateX(-150%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes active-from-right {
  0% {
    opacity: 0;
    transform: translateX(150%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes back-to-screen {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    transform: translate(0, 0);
  }
  90% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}

@keyframes show-drawer {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes hide-drawer-right{
  0%{
    transform: translateX(0%);
  }
  90%{
    opacity: 0;
  }
  100%{
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes hide-drawer-left{
  0%{
    transform: translateX(0%);
  }
  50% {
    opacity: 0;
  }
  100%{
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes show-drawer-content {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes rotate-tiles {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes opacity {
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes popUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  90% {
    transform: translateY(-5%);
    opacity: 0.9;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes success {
  0% {
    transform: rotateZ(15deg);
    transition: transform;
  }
  50% {
    transform: rotateZ(-10deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}


@keyframes opacity-1to0 {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}

@keyframes opacity-0to1 {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100%{
    opacity: 1;
  }
}

