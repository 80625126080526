@import "../0-abstracts/";

.our-products {
  @include display-flex;
  $self: &;
  &__wrapper {
    @include display-flex;
    @include desktop {
      max-width: 1440px;
    }
    #{$self}__items {
      @include display-flex;
      #{$self}__grid-item {
        @include display-flex;
      }
    }
  }
}

.--opacity--0to1 {
  animation: opacity-0to1 0.5s ease 1 forwards !important;
}
