.contact__email-body {
  @include font(
    $color: $black,
    $weight: 300,
    $transform: none,
    $family: "red hat display"
  );
  padding: 18.5px 14px;
  appearance: none;
  outline: none;
  border: 0.25px solid $dark-gray;
  border-radius: 4px;
  font-size: 1em;
  width: 100%;
  margin: 10px 0;
  &::placeholder{
      color: $placeholder-text;
  }
  &:focus,
  &:valid {
    border-color: $blue;
  }
}
