@import "../1-base/";
@import "../0-abstracts/";

.cta-row {
  @include display-flex($height:25vh);
  min-height: 300px;
  background-color: $light-gray;
  $self: &;
  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    #{$self}__content {
      @include display-flex($height: 100%);
      text-align: center;
      @include min-width(1280px) {
        margin-top: unset;
      }
      #{$self}__text-box {
        @include display-flex($direction: row);
        #{$self}__text {
          @include font(
            $family: "poppins",
            $transform: uppercase,
            $weight: 700,
            $color: $black
          );
          span{
            padding: 0px 8px;
            line-height: inherit;
            .budget-list-icon{
              color: $gray;
              min-width: 23px;
              min-height: 23px;
              height: 45px;
              width: 45px;
              vertical-align: middle
            } 
          }
        }
        // #{$self}__text{
        //   @include font(
        //     $family: "red hat display",
        //     $transform: uppercase,
        //     $color: $text-important
        //   );
        // }
      }
      // .--quote-text-box {
      //   z-index: 5;
      //   .--quote-text {
      //     @include font($transform: none, $family: "sora");
      //     filter: drop-shadow(1px 1px 3px rgba(#000, 0.8));
      //   }
      // }
      // .--cta-text-box {
      // }
      // .--online-counseling-text-box {
      //   @include blurred-text($child-tag: "h1");
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100vw;
      //   padding: 16px 32px;
      //   align-items: flex-start;
      //   @include min-width(1280px) {
      //     width: 100%;
      //   }
      //   .--online-counseling-text {
      //     @include font($family: "red hat display", $transform: none);
      //   }
      // }
      .button-box {
        @include display-flex;
        position: relative;
        top: 24px;
      }
    }
  }
}

// .--cta-content {
// }
// .--quote-content {
//   position: relative;
//   background-image: url("../../Assets/Images/Rows/library.jpg");
//   background-size: cover;
//   background-position: center center;
//   text-align: justify;
//   text-indent: 0.25em;
//   background-repeat: no-repeat;
//   @include tablet {
//     background-image: url("../../Assets/Images/Rows/library2.jpg");
//     background-attachment: fixed;
//   }
//   @include min-width(1280px) {
//     background-attachment: unset;
//   }
//   &::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(#000, 0.5);
//     z-index: 2;
//   }
// }
// .--online-counseling-content {
//   @include display-flex($height: 100%, $justify: flex-start);
//   background-image: url("../../Assets/Images/Rows/typing.png");
//   background-size: cover;
//   background-position: top center;
//   background-repeat: no-repeat;
//   position: relative;
//   text-align: justify;
//   text-indent: 0.25em;
//   @include min-width(1014px) {
//     background-image: url("../../Assets/Images/Rows/typing2.png");
//   }
// }
