@import "../0-abstracts/";

.our-products__item-box {
  @include display-flex;
  background: linear-gradient(66deg, rgba(#000, 0.1), transparent);
  max-width: 560px;
  border-radius: 4px;
  box-shadow: 0.1px 0.1px 5px $gray;
  padding: 4px;
  .our-products__item {
    border-radius: 4px 4px 0 0;
    width: 100%;
    max-width: 560px;
    aspect-ratio: calc(16 / 9);
  }
  .our-products__img-item {
    object-fit: contain;
  }
  .our-products__item-text-box {
    @include display-flex($height: 120px);
    margin: 16px 0;
    .our-products__item-text {
      @include font(
        $family: "poppins",
        $type: "",
        $weight: 600,
        $transform: capitalize,
        $color: $black
      );
      opacity: 0;
      margin-bottom: 8px;
      text-align: center;
    }
    .our-products__brand-logo {
      width: 100px;
    }
  }
}
